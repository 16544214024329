import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { isPortRole } from 'components/config/Roles';
import useMissionWorkflowLogic from "hook/useMissionWorkflowLogic";

import { ContextBlock } from "./ContextBlock";
import { ContextMissionDetails } from "./ContextMissionDetails";

import axios from 'api/axios';


const ContextMissionWorkflow = createContext();

const MissionWorkflowProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const [JLBPortMode] = useState(isPortRole(localStorage.getItem("role")));

  //API URL
  const GETWORKFLOW = 'workflow';
  const WORKFLOWBUTTONCLICK = 'mission/workflow/button';

  //composant ref
  const contentRef = useContext(ContextMissionDetails);
  const fileFormRef = useContext(ContextMissionDetails);

  //init mission
  const { getMissionDetail } = useContext(ContextMissionDetails);
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);
  const {filterStatusArrayOld, setFilterStatusArrayOld} = useContext(ContextMissionDetails);
  
  //init param
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { actionSave, setActionSave } = useContext(ContextBlock);
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //workflow state
  const [manualSettingsLoaded, setManualSettingsLoaded] = useState(false);
  const [fieldsSettingsLoaded, setFieldsSettingsLoaded] = useState(false);

  const [checkWorkflowData, setCheckWorkflowData] = useState(true);
  const [controlFieldsError, setControlFieldsError] = useState([]);

  const [workflowConfirmOpen, setWorkflowConfirmOpen] = useState(false);

  const [workflowMailingContent, setWorkflowMailingContent] = useState('');
  const [workflowMailingListContacts, setWorkflowMailingListContacts] = useState([]);
  const [workflowMailingConfirmOpen, setWorkflowMailingConfirmOpen] = useState(false);
  const [workflowMailingCustomiseOpen, setWorkflowMailingCustomiseOpen] = useState(false);

  //treatment
  const [idButtonWorkflow, setIdButtonWorkflow] = useState(null);
  const [controlFieldsBL, setControlFieldsBL] = useState([]);
  const [controlFieldsNote, setControlFieldsNote] = useState([]);
  const [controlFieldsGeneral, setControlFieldsGeneral] = useState([]);
  const [controlFieldsReceiver, setControlFieldsReceiver] = useState([]);
  const [controlFieldsInvoice, setControlFieldsInvoice] = useState([]);
  const [controlFieldsSubInvoice, setControlFieldsSubInvoice] = useState([]);
  
  const { setActiveBlockEdit } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { saveIsFinished, setSaveIsFinished } = useContext(ContextBlock);

  //Logic
  const {
    workflowFind, setWorkflowFind,
    workflowLoaded, setWorkflowLoaded,

    controlFields, setControlFields,
    controlButtons, setControlButtons,
    controlDocuments, setControlDocuments,

    canRead, canWrite, canUpload, isRequired,
    getMissionWorkflow, initWorkflow
  } = useMissionWorkflowLogic({ idMission: idMission, missionCanceled: missionCanceled });


  //useEffect
  useEffect(() => {
    //load conf basic field
    if (!workflowLoaded && !JLBPortMode) {
      var controlFieldsInt = Array();
      controlFieldsInt.push({id:'id_port', required:1});
      controlFieldsInt.push({id:'id_principal', required:1});
      controlFieldsInt.push({id:'id_mission_type', required:1});
      controlFieldsInt.push({id:'id_survey_handler', required:1});
      setControlFields(controlFieldsInt);
    }
    // reload variables if id set
    if (idMission > 0) {
      //Get Workflow configuration
      getMissionWorkflow();
    }
  }, [idMission]);

  useEffect(()=> { 
    setFieldsSettingsLoaded(workflowLoaded || manualSettingsLoaded);
  },[workflowLoaded, manualSettingsLoaded]);

  //if id button, launch validation workflow step
  useEffect(() => {
    if (saveIsFinished && workflowLoaded && checkWorkflowData && idButtonWorkflow != null) {
      var button = controlButtons.find(obj => obj.id === idButtonWorkflow);
      if (button) {
        if (button?.trigger_external_notif !== null) {
          setWorkflowMailingConfirmOpen(true);
        } else {
          if (button?.trigger_confirm !== true) {
            workflowStep();
          } else {
            setWorkflowConfirmOpen(true);
          }
        }
      }
    } else if (!checkWorkflowData) {
      contentRef.contentRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [workflowLoaded, checkWorkflowData, idButtonWorkflow, saveIsFinished]);


  //workflow function
  const { triggerInfosPopin } = useContext(ContextBlock);

  function checkControlFieldsErrorExist(fieldKey, multiFields = false) {
    return !multiFields ? getControlFieldsErrorState(fieldKey) : checkControlMultiFieldsErrorExist(fieldKey);
  }
  function checkControlMultiFieldsErrorExist(fieldKey) {
    var itemId = controlFieldsError.findIndex(obj => obj.id.toUpperCase().substr(0, fieldKey.length) === fieldKey.toUpperCase());
    return (itemId >= 0);
  }
  function getControlFieldsErrorState(fieldKey) {
    var itemId = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === fieldKey.toUpperCase());
    if (itemId >= 0) return controlFieldsError[itemId].error;
    return false;
  }
  function getControlFieldsErrorMsg(fieldKey) {
    var itemId = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === fieldKey.toUpperCase());
    if (itemId >= 0) return controlFieldsError[itemId].helpertext;
    return "";
  }
  //control error by block
  function checkBlockOnWorkflowError(blockRef, controlFieldsMapping = [], multiFields = false) {
    if (controlFieldsError.length > 0) {
      var blockOnEdit = blockEdit.findIndex(obj => (obj.blockRef === blockRef && obj.onEdit === false));
      if (blockOnEdit >= 0) {
        controlFieldsMapping.map((fields) => {
          var blockOnWorkflowError = checkControlFieldsErrorExist(fields, multiFields);
          if (blockOnWorkflowError !== false) {
            setActiveBlockEdit(blockRef);
          }
        });
      }
    }
  }
  //toogle display on edit by block
  function toogleBlockOnEditAndCheckBlockOnWorkflowError(blockRef, currentEdit, setCurrentEdit, controlFieldsMapping = [], multiFields = false) {
    //maj onEdit state
    var blockOnEdit  = blockEdit.findIndex(obj => (obj.blockRef === blockRef && obj.onEdit === true));
    var newStateEdit = blockOnEdit >= 0 ? true : false;
    
    if (currentEdit != newStateEdit) {
      var fieldsBlockInError = false;
      if (controlFieldsError.length > 0) {
        controlFieldsMapping.map((fields) => {
          var blockOnWorkflowError = checkControlFieldsErrorExist(fields, multiFields);
          if (blockOnWorkflowError !== false) {
            fieldsBlockInError = true;
            if (!newStateEdit) {
              setActiveBlockEdit(blockRef);
            }
          }
        });
      }
      if (!fieldsBlockInError || newStateEdit) {
        setCurrentEdit(newStateEdit);
      }
    }
  }

  async function requiredFieldsErrorLog(itemKey, itemVal, errorKey) {
    //check field is a controlled field
    var fieldControl = controlFields.findIndex(obj => obj.id.toUpperCase() === itemKey.toUpperCase());
    if (fieldControl >= 0) {
      //delete error field log if exist
      var errorId  = controlFieldsError.findIndex(obj => obj.id.toUpperCase() === errorKey.toUpperCase());
      if (errorId >= 0) controlFieldsError.splice(errorId, 1);
      //check valid data
      if (isRequired(itemKey) && (!!itemVal == false || itemVal == "null" || itemVal == "0")) {
        //init error msg
        controlFieldsError.push({id: errorKey, error: true, helpertext: 'Required value'});
        //init false not valid workflow
        setCheckWorkflowData(false);
      }
    }
  }

  async function controlBasicFieldsData(data) {
    if (typeof data != "undefined") {
      for (const item of data.entries()) {
        var itemKey = item[0], itemVal = item[1], errorKey = item[0];
        //check&log required field
        requiredFieldsErrorLog(itemKey, itemVal, errorKey);
      }
      setControlFieldsError(controlFieldsError);
    } else {
      //init false not valid workflow
      setCheckWorkflowData(false);
    }
  }

  async function controlFieldsData(data) {
    if (workflowLoaded) {
      if (typeof data != "undefined") {
        for (const item of data.entries()) {
          var itemKey = item[0], itemVal = item[1], errorKey = item[0];
          //check&log required field
          requiredFieldsErrorLog(itemKey, itemVal, errorKey);
        }
        setControlFieldsError(controlFieldsError);
      } else {
        //init false not valid workflow
        setCheckWorkflowData(false);
      }
    }
  }

  async function controlMultipleFieldsData(data) {
    if (workflowLoaded) {
      if (typeof data != "undefined") {
        for (const item of data) {
          var itemKey = item.key, itemVal = item.val, errorKey = item.key+item.id;
          //check&log required field
          requiredFieldsErrorLog(itemKey, itemVal, errorKey);
        }
        setControlFieldsError(controlFieldsError);
      } else {
        //init false not valid workflow
        setCheckWorkflowData(false);
      }
    }
  }

  async function controlDocumentsData(data) {
    if (workflowLoaded) {
      //check required type exist
      var requiredDoc = controlDocuments.findIndex(obj => obj.required === 1);
      if (requiredDoc >= 0) {
        if (!!data != false) {
          var docError = [], docTypes = fileFormRef.current.getFileTypesList();
          for (const item of controlDocuments) {
            var itemType = item.id_file_type, itemRequired = item.required;
            //check data doc type required exist
            if (itemRequired) {
              var checkData = data.findIndex(obj => obj.type === itemType);
              //if not doc required then "log" error
              if (checkData < 0) {
                var docTypeIndex = docTypes.findIndex(obj => obj.value === itemType);
                docError.push(docTypes[docTypeIndex].label);
              }
            }
          }
        } else {
          //get label of type
          var docError = [];
          controlDocuments.map((elmt, i) => {
            if(elmt.required)
              docError.push(elmt.label);
          });
        }

        //open error poppin if error exist
        if (docError.length > 0) {
          //set error msg
          var errorFileTypesList = docError.join("\n- ");
          var errorMsg = "- " + errorFileTypesList;
          //open error poppin
          triggerInfosPopin("Files required", errorMsg, "error");
          //init false not valid workflow
          setCheckWorkflowData(false);
        }
      }
    }
  }

  async function controlFieldsErrorTruncate(controlFieldsMapping = null) {
    if (controlFieldsMapping != null && controlFieldsMapping.length > 0) {
      if (controlFieldsError.length > 0) {
        var fieldsError = structuredClone(controlFieldsError);
        controlFieldsMapping.map((fields) => {
          if (fieldsError.length > 0) {
            fieldsError = fieldsError.filter(obj => obj.id.toUpperCase() !== fields.toUpperCase());
          }
        });
        if (fieldsError.length != controlFieldsError.length) {
          setControlFieldsError(fieldsError);
        }
      }
    } else {
      setControlFieldsError([]);
    }
  }

  //data function
  const getWorkflow = async (missionType) => {
    try {
      var config = {
        url: GETWORKFLOW+"?id_mission_type="+missionType,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        }
      };

      const reponseGet = await axios(config);
      reponseGet.data.data.steps.sort((a, b) => (a.status.value > b.status.value) ? 1 : -1);
      
      var currentStatus = 2;  //status:2 of start of creation mission
      if(idMission > 0) currentStatus = parseInt(filterStatusArrayOld[0]);
      var index = currentStatus-1;

      //INITIALISATION FIELDS - BUTTONS - DOCUMENTS
      initWorkflow(reponseGet.data.data.steps[index]);
      setWorkflowFind(true);
      setWorkflowLoaded(true);
      navigate(window.location, { replace: false });
    } catch (err) {
      setWorkflowFind(false);
      setWorkflowLoaded(false);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
    }
  }

  const workflowMailingStep = async () => {
    var button = controlButtons.find(obj => obj.id === idButtonWorkflow);
    if (button) {
      if (button?.trigger_external_notif !== true) {
        workflowStep(true);
      } else {
        setWorkflowMailingCustomiseOpen(true);
      }
    }
  }

  const workflowStep = async (sendEmail = false) => {
    //CLICK WORKFLOW BUTTON
    setLoading(true);
    setLoadingUpdate(false);
    var missionHasChanged = false;
    try {
      var data = new FormData();
      data.append('id_mission', idMission);
      data.append('id_button', idButtonWorkflow);

      //add data mailing if exist
      data.append('send_email', sendEmail ? 1 : 0);
      if (sendEmail) {
        if (workflowMailingListContacts.length > 0) {
          data.append('email_content', workflowMailingContent);
          workflowMailingListContacts.forEach(contact => data.append('email_to[]', contact.email));
        }
      }

      var config = {
        url: WORKFLOWBUTTONCLICK,
        method: 'post',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: data
      };
      const response = await axios(config);
      
      if (!!response.data?.success !== false && !!response.data?.data !== false) {
        const mission = response.data.data;
        setActionSave(1);
        setIdMision(mission.id);
        missionHasChanged = true;
        navigate('/missionDetails?id=' + mission.id + '&actionSave=1', { replace: true });
        contentRef.contentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoading(false);
      setLoadingUpdate(true);
      setSaveIsFinished(false);
      setIdButtonWorkflow(null);
      //reload data api
      if (!missionHasChanged) {
        await getMissionDetail();
        await getMissionWorkflow();
      }
    }
  }


  return (
    <ContextMissionWorkflow.Provider
      value=
      {{
        workflowFind, setWorkflowFind,
        workflowLoaded, setWorkflowLoaded,
        controlFields, setControlFields,
        controlFieldsError, setControlFieldsError,
        controlDocuments, setControlDocuments,
        controlButtons, setControlButtons,
        checkWorkflowData, setCheckWorkflowData,
        fieldsSettingsLoaded, setFieldsSettingsLoaded,

        controlFieldsNote, setControlFieldsNote,
        controlFieldsGeneral, setControlFieldsGeneral,
        controlFieldsReceiver, setControlFieldsReceiver,
        controlFieldsBL, setControlFieldsBL,
        controlFieldsSubInvoice, setControlFieldsSubInvoice,
        controlFieldsInvoice, setControlFieldsInvoice,
        
        checkBlockOnWorkflowError,
        toogleBlockOnEditAndCheckBlockOnWorkflowError,

        getWorkflow, canRead, canWrite, canUpload, isRequired,
        controlFieldsData, controlMultipleFieldsData, controlDocumentsData, controlBasicFieldsData, controlFieldsErrorTruncate,
        checkControlMultiFieldsErrorExist, getControlFieldsErrorState, getControlFieldsErrorMsg,
        workflowStep, workflowMailingStep,

        idButtonWorkflow, setIdButtonWorkflow,

        workflowConfirmOpen, setWorkflowConfirmOpen,
        workflowMailingContent, setWorkflowMailingContent,
        workflowMailingListContacts, setWorkflowMailingListContacts,
        workflowMailingConfirmOpen, setWorkflowMailingConfirmOpen,
        workflowMailingCustomiseOpen, setWorkflowMailingCustomiseOpen
      }}
    >
      {children}
    </ContextMissionWorkflow.Provider>
  );
};

export { MissionWorkflowProvider, ContextMissionWorkflow };
