import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { isAdminRole, isPortRole, isCustomerRole } from 'components/config/Roles';

import axios from 'api/axios';


const useMissionWorkflowLogic = (props) => {

  //init config
  const navigate = useNavigate();
  const [JLBPortMode] = useState(isPortRole(localStorage.getItem("role")));

  //API URL
  const GETMISSIONWORKFLOW = 'mission/workflow';

  //workflow state
  const [workflowFind, setWorkflowFind] = useState(false);
  const [workflowLoaded, setWorkflowLoaded] = useState(false);

  const [controlFields, setControlFields] = useState([]);
  const [controlButtons, setControlButtons] = useState([]);
  const [controlDocuments, setControlDocuments] = useState([]);

  //properties
  const {
    idMission,
    missionCanceled
  } = props;

  //data config workflow
  //MAPPING FIELD'S KEY => NAME
  const controlFieldsMapping = [];
  //general
  controlFieldsMapping["mission_type"] = "id_mission_type";
  controlFieldsMapping["port"] = "id_port";
  controlFieldsMapping["principal"] = "id_principal";
  controlFieldsMapping["principals_ref"] = "principal_ref";
  controlFieldsMapping["survey_handler"] = "id_survey_handler";
  controlFieldsMapping["local_office"] = "id_local_office";
  controlFieldsMapping["local_surveyor"] = "id_local_surveyor";
  controlFieldsMapping["vessel"] = "id_vessel";
  controlFieldsMapping["insured"] = "id_insured";
  controlFieldsMapping["trader"] = "id_trader";
  controlFieldsMapping["eta"] = "estimated_time_arrival";
  controlFieldsMapping["etb"] = "estimated_time_berthing";
  controlFieldsMapping["date_completion_operations"] = "operations_completion_date";
  controlFieldsMapping["current_stage"] = "id_current_stage";
  //receiver
  controlFieldsMapping["receiver_item"] = "id_receiver";
  controlFieldsMapping["receiver_item_tonnage"] = "r.tonnage";
  controlFieldsMapping["receiver_item_cargo"] = "id_cargo";
  controlFieldsMapping["receiver_item_mission_ending"] = "id_mission_ending";
  //receiver lot
  controlFieldsMapping["lot_item_packaging"] = "id_packaging";
  controlFieldsMapping["lot_item_origin"] = "id_cargo_origin";
  controlFieldsMapping["lot_item_type_of_loading"] = "id_loading_type";
  controlFieldsMapping["lot_item_brand"] = "id_cargo_brand";
  controlFieldsMapping["lot_item_quality"] = "id_cargo_quality";
  controlFieldsMapping["lot_item_quantity"] = "packaging_quantity";
  controlFieldsMapping["lot_item_net_tonnage"] = "net_tonnage";
  controlFieldsMapping["lot_item_gross_tonnage"] = "gross_tonnage";
  controlFieldsMapping["lot_item_empty_spare_packaging_percentage"] = "empty_spare_packaging_percentage";
  //invoice
  controlFieldsMapping["invoice_note"] = "invoices_note";


  //workflow function
  function canRead(key) {
    if ((!workflowFind && !JLBPortMode) || isAdminRole(localStorage.getItem("role"))) return 1;
    var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
    if (itemId >= 0) return controlFields[itemId].read;
    return 0;
  }

  function canWrite(key) {
    if (missionCanceled) return 0;
    if ((!workflowFind && !JLBPortMode) || isAdminRole(localStorage.getItem("role"))) return 1;
    var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
    if (itemId >= 0) return controlFields[itemId].write;
    return 0;
  }

  function canUpload(id) {
    if (missionCanceled) return false;
    if (!workflowFind) return true;
    var itemId = controlDocuments.findIndex(obj => obj.id_file_type === id);
    if (itemId >= 0) return true;
    return false;
  }

  function isRequired(key, elmt) {
    switch (elmt) {
      case 'file':
        var itemId = controlDocuments.findIndex(obj => obj.id_file_type === key);
        if (itemId >= 0) return Boolean(controlDocuments[itemId].required);
        break;

      default:
        var itemId = controlFields.findIndex(obj => obj.id.toUpperCase() === key.toUpperCase());
        if (itemId >= 0) return Boolean(controlFields[itemId].required);
        break;
    }
    return false;
  }

  //data function
  function initWorkflow(data) {
    var item = {};
    var itemIndex = 0;

    //INITIALISATION FIELDS
    var controlFieldsInt = Array();
    for (let indexField = 0; indexField < data.fields.length; indexField++) {
      var key = data.fields[indexField].key;
      var keyName  = (typeof controlFieldsMapping[key] != "undefined" ? controlFieldsMapping[key] : key);
      var required = data.fields[indexField].required;
      var read = 0; var write = 0;
      for (var permission = 0; permission < data.fields[indexField].permissions.length; permission++) {
        if (data.fields[indexField].permissions[permission].user_group.role == localStorage.getItem("role")) {
          read  = data.fields[indexField].permissions[permission].read;
          write = data.fields[indexField].permissions[permission].write;
          break;
        }
      }
      item = {id:keyName, required:required, read:read, write:write};
      controlFieldsInt.push(item);
    }

    //INITIALISATION BUTTONS
    var controlButtonsInt = Array();
    for (let indexButton = 0; indexButton < data.buttons.length; indexButton++) {
      var key = data.buttons[indexButton].key;
      for (var permission = 0; permission < data.buttons[indexButton].permissions.length; permission++) {
        if (data.buttons[indexButton].permissions[permission].user_group.role == localStorage.getItem("role")
        || isAdminRole(localStorage.getItem("role"))) {
          itemIndex = controlButtonsInt.findIndex(obj => obj.id === key);
          //retrieve triggers list
          var trigger = null, trigger_action = null, trigger_confirm = null;
          data.buttons[indexButton]?.triggers?.map((obj) => {
            if (obj?.action?.notification?.type == 'notif_email') {
              if (obj?.action?.notification?.to?.length > 0) {
                Boolean(obj?.action?.notification?.customisable) && obj?.action?.notification?.to?.some(toj => isCustomerRole(toj.user_group.role)) 
                ? trigger = true
                : trigger = false;
              }
              if (Boolean(obj?.action?.notification?.customisable) && obj?.action?.notification?.to?.some(toj => isCustomerRole(toj.user_group.role))) {
                trigger_action = obj?.action;
              }
            } else if (obj?.action?.type == 'mission_duplicating') {
              trigger_confirm = true;
              trigger_action = obj?.action;
            }
           });

          item = { id: data.buttons[indexButton].id, key: key, label: data.buttons[indexButton].label, trigger_action: trigger_action, trigger_confirm: trigger_confirm, trigger_external_notif: trigger};
          
          if (itemIndex < 0) controlButtonsInt.push(item);
          else controlButtonsInt[itemIndex] = item;

          break;
        }
      }
    }

    //INITIALISATION DOCUMENTS
    var controlDocumentsInt = Array();
    for (let indexDoc = 0; indexDoc < data.documents.length; indexDoc++) {
      var key = data.documents[indexDoc].key;
      for (var permission = 0; permission < data.documents[indexDoc].permissions.length; permission++) {
        if (data.documents[indexDoc].permissions[permission].user_group.role == localStorage.getItem("role")
        || isAdminRole(localStorage.getItem("role"))) {
          itemIndex = controlDocumentsInt.findIndex(obj => obj.id === key);
          item = {
            key:key, 
            id:data.documents[indexDoc].id, 
            label:data.documents[indexDoc].label, 
            required:data.documents[indexDoc].required,
            id_file_type:data.documents[indexDoc].id_file_type
          };

          if (itemIndex < 0) controlDocumentsInt.push(item);
          else controlDocumentsInt[itemIndex] = item;

          break;
        }
      }
    }
    
    setControlFields(controlFieldsInt);
    setControlButtons(controlButtonsInt);
    setControlDocuments(controlDocumentsInt);
  }

  const getMissionWorkflow = async () => {
    try {
      if (idMission != 0) {
        var data = new FormData();
        var jsonRequestData = JSON.stringify(Object.fromEntries(data));

        var config = {
          url: GETMISSIONWORKFLOW+"?id_mission="+idMission,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestData,
        };

        const reponseGet = await axios(config);
        reponseGet.data.data.workflow.steps.sort((a, b) => (a.status.value > b.status.value) ? 1 : -1);

        var currentStatus = reponseGet.data.data.mission_status;
        var index = currentStatus-1;

        //INITIALISATION FIELDS - BUTTONS - DOCUMENTS
        initWorkflow(reponseGet.data.data.workflow.steps[index]);
        setWorkflowFind(true);
        setWorkflowLoaded(true);
      }
    } catch (err) {
      setWorkflowFind(false);
      setWorkflowLoaded(false);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
    }
  }


  return {
    workflowFind, setWorkflowFind,
    workflowLoaded, setWorkflowLoaded,

    controlFields, setControlFields,
    controlButtons, setControlButtons,
    controlDocuments, setControlDocuments,

    canRead, canWrite, canUpload, isRequired,
    getMissionWorkflow, initWorkflow
  };
};

export default useMissionWorkflowLogic;
