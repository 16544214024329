import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, onDisConfirm } = props;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };
  
  const handleClose = () => {
    setOpen(false)
  }
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      className={!!props.onDisConfirm !== false || !!props.notError !== false ? 'modalPopin info workflow' : 'modalPopin info error'}>
      <Box sx={style}>
        <div className="headerPopin">
          Confirm {title}
          <span className="closePopin">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
        </div>
        <div className="contentPopin">{children}</div>
        <div className='footerPopin'>
          <Box textAlign="center" sx={{ '& > button': { m: 1 } }}>
            <LoadingButton
              onClick={handleClose}
              loadingIndicator="Cancel"
              variant="outlined"
              startIcon={<CloseIcon/>}
            >
              {props?.cancelButtonLabel ? props?.cancelButtonLabel : 'Cancel'}
            </LoadingButton>

            {!!props.onDisConfirm !== false && 
            <LoadingButton
              onClick={(event) => {
                if (!!props.notClose == false) {
                  handleClose();
                }
                onDisConfirm();
              }}
              variant="contained"
              startIcon={<UnpublishedIcon/>}
            >
              {props?.disConfirmButtonLabel ? props?.disConfirmButtonLabel : 'No'}
            </LoadingButton>
            }

            <LoadingButton
              onClick={(event) => {
                if (!!props.notClose == false) {
                  handleClose();
                }
                onConfirm(event, props.params);
              }}
              color={!!props.onDisConfirm !== false || !!props.notError !== false ? 'secondary' : 'error'}
              variant="contained"
              startIcon={<CheckCircleIcon/>}
            >
              {props?.confirmButtonLabel ? props?.confirmButtonLabel : 'Yes'}
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmDialog;
