import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";

import axios from 'api/axios';


const ContextMissionDetailsNote = createContext();

const MissionDetailsNoteProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { update } = useContext(ContextBlock);
  const { noteRef } = useContext(ContextMissionDetails);
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_URL = 'mission';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //workflow
  const { controlFieldsData } = useContext(ContextMissionWorkflow);
  const { checkBlockOnWorkflowError } = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);

  const {controlFieldsNote, setControlFieldsNote} = useContext(ContextMissionWorkflow);

  //init id mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  //mission general state
  const {general, setGeneral} = useContext(ContextMissionDetails);

  //context
  const {internalComment, setInternalComment} = useContext(ContextMissionDetails);
  const {publicComment, setPublicComment} = useContext(ContextMissionDetails);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const {saveBlockAndCheckWorkflow} = useContext(ContextMissionDetailsTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async () => {
    await controlFieldsErrorTruncate();
    handleClickBlockCancel(noteRef);
  }

  //fields mapping
  const controlFieldsMapping = []; 
  controlFieldsMapping.push('internal_note');
  controlFieldsMapping.push('public_note');


  //useEffect
  useEffect(() => {
    setControlFieldsNote(controlFieldsMapping);
  }, []);

  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //dont update data if edition is in progress
      var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === noteRef && obj.onEdit === false));
      if (blockInEdit >= 0) {
        //(RE)INIT detect change
        if (reloadData > oldReloadData) {
          setOldReloadData(reloadData);
        }

        //INIT internal_note
        if (general.internal_note) setInternalComment(general.internal_note);
        else setInternalComment('');
        
        //INIT public_note
        if (general.public_note) setPublicComment(general.public_note);
        else setPublicComment('');
      }
    }
  }, [general, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(noteRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === noteRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(noteRef, saveMissionDetailsNote, checkWorkflowMissionDetailsNote);
  }, [blockSave, idMission]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === noteRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);

  //check workflow error
  useEffect(() => {
    checkBlockOnWorkflowError(noteRef, controlFieldsMapping);
  }, [controlFieldsError, blockEdit]);


  //function treatment
  const getDataSaveMissionDetailsNote = async () => {
    var data = new FormData();
    data.append('id_mission', Number(idMission));
    data.append('internal_note', internalComment);
    data.append('public_note', publicComment);

    return data;
  }

  const saveMissionDetailsNote = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      var data = await getDataSaveMissionDetailsNote();
      var dataMission = data;

      //API DATA SENT
      var jsonRequestData = JSON.stringify(Object.fromEntries(data));
      var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');    //PATCH ERREUR NULL

      var config = {
        url: MISSION_URL,
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestDataFinal
      };
      const response = await axios(config);

      //CHECK WORKFLOW DATA
      await checkWorkflowMissionDetailsNote(dataMission);

      if (justBlock) {
        setSnackBarMessage("Notes saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getMissionDetail();
      } else {
        setDoneBlockSave(noteRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(noteRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  };

  const checkWorkflowMissionDetailsNote = async (dataMission = null) => {
    //CHECK WORKFLOW DATA
    await controlFieldsData(!!dataMission != false ? dataMission : await getDataSaveMissionDetailsNote());
  };

  async function controlFieldsErrorTruncate() {
    if (controlFieldsError.length > 0) {
      var fieldsError = structuredClone(controlFieldsError);
      controlFieldsMapping.map((fields) => {
        if (fieldsError.length > 0) {
          fieldsError = fieldsError.filter(obj => obj.id.toUpperCase() !== fields.toUpperCase());
        }
      });
      if (fieldsError.length != controlFieldsError.length) {
        setControlFieldsError(fieldsError);
      }
    }
  }


  return (
    <ContextMissionDetailsNote.Provider
      value=
      {{
        handleClickCancel,
        controlFieldsMapping,
        saveMissionDetailsNote
      }}
    >
      {children}
    </ContextMissionDetailsNote.Provider>
  );
};

export { MissionDetailsNoteProvider, ContextMissionDetailsNote };
