import React, { useState, useRef, useLayoutEffect } from 'react';
import { DataGridPro, useGridApiRef, GridToolbarContainer, useGridApiContext, ChangeEvent } from '@mui/x-data-grid-pro';

import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import DesktopDatePickerCustom from 'components/select/DesktopDatePickerCustom';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import TextFieldCustom from 'components/select/TextFieldCustom';
import { getCastTypeFromValue } from 'components/page/object/Utils';
import SelectCustom from 'components/select/SelectCustom';

import momentDate from 'moment';

// custom edit cell type date
const DataGridDatePickerCustom = (props) => {
  const [autoOpen, setAutoOpen] = useState(false);
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const row = props.row;
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus();
      setAutoOpen(true);
    }
  }, [hasFocus]);

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const stringValue = event._d.toLocaleDateString();
      if (momentDate(stringValue, 'DD/MM/YYYY', true).isValid()) {
        const newSplitValue = stringValue.split('/'); // The new value entered by the user
        const newValue = newSplitValue[2] + '-' + newSplitValue[1] + '-' + newSplitValue[0];
        apiRef.current.setEditCellValue({ id, field, value: newValue });
        props.processRowUpdate({ ...row, [field]: newValue });
      }
    } else {
      apiRef.current.setEditCellValue({ id, field, value: null });
      props.processRowUpdate({ ...row, [field]: null });
    }
  };

  var write = props?.write ? props.write : 1;
  if (write) return (
    <DesktopDatePickerCustom
      value={value}
      inputRef={ref}
      autoOpen={autoOpen}
      views={props?.views ? props.views : null}
      onChange={handleValueChange}
      read={props?.read ? props.read : 1}
      write={write}
      controlFieldsStatus={props?.control ? props.control : 0}
    />
  )
}

// custom edit cell type select
const DataGridSelectCustom = (props) => {
  const [autoOpen, setAutoOpen] = useState(false);
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const row = props.row;
  const colDef = props.colDef;
  const api = props.api;
  const valueOptions = typeof colDef.valueOptions !== 'function' ? colDef.valueOptions : colDef.valueOptions({ row });
  var selectValue = value;
  const ref = useRef();

  //auto focus field
  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus();
      setAutoOpen(true);
    }
  }, [hasFocus]);

  //check value exist
  if (!props?.multiple && selectValue !== 0 && selectValue !== '') {
    const castType = getCastTypeFromValue(selectValue);
    const option = valueOptions.find((obj) => castType(selectValue) === castType(obj.value));
    //if value not exist in list, re-init value at 0
    if (!option) {
      //set value for select
      selectValue = 0;
      //set value for xgrid
      if (apiRef.current) {
        apiRef.current.setEditCellValue({ id, field, value: selectValue });
        colDef.valueFormatter({ id, value: selectValue, field, api });
      }
      //set value for api
      props.processRowUpdate({ ...row, [field]: selectValue });
    }
  } else if (props?.multiple && selectValue.length > 0) {
    selectValue.map((val, idx) => {
      const castType = getCastTypeFromValue(val);
      const option = valueOptions.find((obj) => castType(val) === castType(obj.value));
      //if value not exist in list, re-init value at 0
      if (!option) {
        //set value for select
        delete selectValue[idx];
        //set value for xgrid
        if (apiRef.current) {
          apiRef.current.setEditCellValue({ id, field, value: selectValue });
          colDef.valueFormatter({ id, value: selectValue, field, api });
        }
        //set value for api
        props.processRowUpdate({ ...row, [field]: selectValue });
      }
    });
  }

  //init label to display who correspond at the value
  if (typeof value === 'string' && value !== '') {
    const option = valueOptions.find(
      ({ label: optionLabel }) => value === optionLabel
    );
    if (option) {
      selectValue = option.value;
    }
  }

  const handleCreator = (data) => {
    return props?.setCreatorHandle ? props.setCreatorHandle(data) : { id: data.value, title: data.label, alias: data.label, labelValue: data.label };
  }

  const setNewValue = (newValue) => {
    //set value for xgrid
    if (apiRef.current) {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }
    //set value for api
    props.processRowUpdate({ ...row, [field]: newValue });
    //treatment of value and dataGrid interaction
    if (!!props?.keepCellEditMode === false) {
      //stop edit mode and formatte value
      if (apiRef.current) {
        apiRef.current.stopCellEditMode({ id, field });
      }
      colDef.valueFormatter({ id, value: newValue, field, api });
    }
  }

  const checkNewValue = (newValue, newLabel = null, newAlias = null, newData = null) => {
    const defaultValue = typeof newValue === 'string' ? '' : 0;
    const castType = getCastTypeFromValue(newValue);
    var optionKey = valueOptions.findIndex(options => castType(options.value) === castType(newValue));
    if (optionKey >= -1) {
      //update options
      if (optionKey <= -1 && !!props?.setValueOptions !== false) {
        if (((typeof newValue === 'string' && newValue != '') || (typeof newValue !== 'string' && newValue > 0)) 
          && !!newLabel !== false) {
          var label = newLabel;
          if (!!props?.apiAddBackValueConcat !== false && !!newAlias !== false) label += ' - ' + newAlias;

          valueOptions.push({ id: newValue, value: newValue, label: label });
          optionKey = valueOptions.findIndex(options => castType(options.value) === castType(newValue));
          props.setValueOptions(valueOptions);
        }
      }

      if (!props?.multiple) {
        return (optionKey <= -1 ? defaultValue : newValue);
      } else {
        return optionKey;
      }
    }
  }

  const handleValueChange = (newValue: ChangeEvent<HTMLInputElement>, newLabel = null, newAlias = null, newData = null) => {
    if (props?.multiple) {
      if (newValue.length > 0) {
        newValue.map((val, idx) => {
          const optionKey = checkNewValue(val, newLabel ? newLabel[idx] : newLabel);
          if (optionKey == -1) delete newValue[idx];
        });
      }
      setNewValue(newValue);
    } else {
      const checkedValue = checkNewValue(newValue, newLabel, newAlias, newData);
      setNewValue(checkedValue);
    }
  }

  var write = props?.write ? props.write : 1;
  if (write) return (
    <SelectCustom
      inputRef={ref}
      autoOpen={autoOpen}
      
      title={props?.title ? props.title : ''}
      multiple={props?.multiple ? props.multiple : false}
      enableAddButton={props?.enableAddButton ? props.enableAddButton : false}
      companySelectRefresh={props?.companySelectRefresh ? props.companySelectRefresh : null}
      classType={props?.classType ? props.classType : null}
      apiAddButton={props?.apiAddButton ? props.apiAddButton : null}
      field1={props?.field1 ? props.field1 : null}
      field2={props?.field2 ? props.field2 : null}
      field3={props?.field3 ? props.field3 : null}
      field4={props?.field4 ? props.field4 : null}
      field5={props?.field5 ? props.field5 : null}
      fieldText={props?.fieldText ? props.fieldText : null}
      cached={props?.cached ? props.cached : null}
      cacheTTL={props?.cacheTTL ? props.cacheTTL : null}
      resetCache={props?.resetCache ? props.resetCache : null}

      datas={valueOptions}
      dataValue={selectValue}
      creator={handleCreator}
      setDataValue={handleValueChange}
      read={props?.read ? props.read : 1}
      write={write}
      required={props?.required ? props.required : false}
      controlFieldsStatus={props?.control ? props.control : 0}
      disableNone={props?.disableNone ? props.disableNone : false}

      error={props?.error ? props.error : false}
      infos={props?.infos ? props.infos : false}
      helperText={props?.helperText ? props.helperText : null}
    />
  )
}


// custom edit cell type select
const DataGridSelectAutoCustom = (props) => {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const row = props.row;
  const colDef = props.colDef;
  const api = props.api;
  const valueOptions = typeof colDef.valueOptions !== 'function' ? colDef.valueOptions : colDef.valueOptions({ row });
  var selectValue = value;
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus();
    }
  }, [hasFocus]);

  //check value exist
  if (selectValue !== 0) {
    const option = valueOptions.find((obj) => selectValue === obj.id);
    //if value not exist in list, re-init value at 0
    if (option) {
      selectValue = option.label;
    }
  }

  const handleCreator = (data) => {
    return props?.setCreatorHandle ? props.setCreatorHandle(data) : { id: data.id, label: data.label, alias: !!data?.alias !== false ? data?.alias : data.label, labelValue: data.label };
  }

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>, newLabel = null, newAlias = null, newData = null) => {
    var newValue = Number(event);
    var optionKey = valueOptions.findIndex(options => Number(options.id) === newValue);
    //update options
    if (!!props?.setValueOptions !== false) {
      if (optionKey <= -1) {
        if (newValue > 0 && !!newLabel !== false) {
          var newOption = props?.setCreatorHandle && newData ? props.setCreatorHandle(newData) : { id: newValue, value: newValue, label: newLabel, alias: newLabel, labelValue: newLabel };
          valueOptions.push(newOption);
          optionKey = valueOptions.findIndex(options => Number(options.id) === newValue);
          props.setValueOptions(valueOptions);
        }
      } else if (props?.enableUpdButton) {
        var newOption = props?.setCreatorHandle && newData ? props.setCreatorHandle(newData) : { id: newValue, value: newValue, label: newLabel, alias: newLabel, labelValue: newLabel };
        var newOptions = valueOptions.map(option => {
          if (option.id == newOption.id) option = newOption;
          return option;
        });
        props.setValueOptions(newOptions);
      }
    }

    if (optionKey >= -1) {
      const value = (optionKey <= -1 ? '' : newValue);
      //set value for xgrid
      if (apiRef.current) {
        apiRef.current.setEditCellValue({ id, field, value: value });
      }
      //set value for api
      props.processRowUpdate({ ...row, [field]: value });
      //treatment of value and dataGrid interaction
      if (!!props?.keepCellEditMode === false) {
        //stop edit mode and formatte value
        if (apiRef.current) {
          apiRef.current.stopCellEditMode({ id, field });
        }
        colDef.valueFormatter({ id, value: value, field, api });
      }
    }
  };

  var write = props?.write ? props.write : 1;
  if (write) return (
    <SelectAutoCustom
      inputRef={ref}

      api={props?.apiUrl ? props.apiUrl : ''}
      apiParam={props?.apiParam ? props.apiParam : ''}
      apiParamValue={props?.apiParamValue ? props.apiParamValue : ''}

      title={props?.title ? props.title : ''}
      multiple={props?.multiple ? props.multiple : false}
      enableAddButton={props?.enableAddButton ? props.enableAddButton : false}
      enableUpdButton={props?.enableUpdButton ? props.enableUpdButton : false}
      companySelectRefresh={props?.companySelectRefresh ? props.companySelectRefresh : null}
      classType={props?.classType ? props.classType : null}
      apiAddButton={props?.apiAddButton ? props.apiAddButton : null}
      apiUpdButton={props?.apiAddButton ? props.apiAddButton : null}
      field1={props?.field1 ? props.field1 : null}
      field2={props?.field2 ? props.field2 : null}
      field3={props?.field3 ? props.field3 : null}
      field4={props?.field4 ? props.field4 : null}
      fieldText={props?.fieldText ? props.fieldText : null}
      cached={props?.cached ? props.cached : null}
      cacheTTL={props?.cacheTTL ? props.cacheTTL : null}
      resetCache={props?.resetCache ? props.resetCache : null}

      variant={props?.variant ? props.variant : null}
      resultFormat={props?.resultFormat ? props.resultFormat : null}
      renderOption={props?.renderOption ? props.renderOption : null}
      sizeComponents={props?.sizeComponents ? props.sizeComponents : null}

      dataValue={selectValue}
      dataValueId={value}
      setDataValue={handleValueChange}
      creator={handleCreator}
      read={props?.read ? props.read : 1}
      write={write}
      required={props?.required ? props.required : false}
      controlFieldsStatus={props?.control ? props.control : 0}

      error={props?.error ? props.error : false}
      infos={props?.infos ? props.infos : false}
      helperText={props?.helperText ? props.helperText : null}
    />
  )
}

// custom edit cell type select
const DataGridTextCustom = (props) => {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const row = props.row;
  const ref = useRef();

  //auto focus
  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    props.processRowUpdate({ ...row, [field]: newValue });
  };

  var write = props?.write ? props.write : 1;
  if (write) return (
    <TextFieldCustom
      inputRef={ref}
      value={value}
      type={props?.type ? props?.type : 'text'}
      fullWidth={props?.fullWidth ? true : false}
      multiline={props?.multiline ? true : false}
      variant="standard"
      id={"dataGridTextCustom-" + field + id}
      onBlur={handleValueChange}
      read={props?.read ? props.read : 1}
      write={write}
      controlFieldsStatus={props?.control ? props.control : 0}
    />
  )
}

const DataGridListValueFormatterCustom = (rowId, value, field, api) => {
  //get row & col of grid
  const row = api.getRow(rowId);
  const colDef = api.getColumn(field);
  //retrieve options list
  const valueOptions = typeof colDef.valueOptions !== 'function' ? colDef.valueOptions : colDef.valueOptions({ row });
  //get fonction of type format
  const castType = getCastTypeFromValue(value);
  //found value in list
  const option = valueOptions.find(
    ({ value: optionValue }) => castType(value) === castType(optionValue)
  );
  if (option) return option.label;
}

const DataGridRenderCellEditableCustom = (cell) => {
  if ((cell.value == '' || cell.value == null || cell.value == 0) && !!cell.colDef?.editable === true) {
    if (!!cell.colDef?.required === true) {
      return (
        <Tooltip title="Double click to edit" placement="top">
          <div className="dataGridRenderCellSkeleton">
            <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Double click to edit" placement="top">
          <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
        </Tooltip>
      );
    }
  }
}

export { DataGridPro, useGridApiRef, GridToolbarContainer, DataGridDatePickerCustom, DataGridSelectCustom, DataGridSelectAutoCustom, DataGridTextCustom, DataGridListValueFormatterCustom, DataGridRenderCellEditableCustom };
