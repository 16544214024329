import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import TextFieldCustom from 'components/select/TextFieldCustom';
import useMissionWorkflowLogic from "hook/useMissionWorkflowLogic";

import 'styles/Popin.css';
import Moment from 'moment';
import { saveAs } from 'file-saver';
import axios from 'api/axios';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InternalNotePopin = forwardRef((props, ref) => {
  const navigate = useNavigate();

  //API URL
  const MISSION_URL = 'mission';

  //state
  const [loadingSave, setLoadingSave] = useState(false);

  //properties
  const {
    setOpenSnackBar,
    open, setOpen, params,
    rows, setRows, 
    totalRows, setTotalRows
  } = props;

  //params
  const {
    missionId,
    missionCanceled,
    note
  } = params;

  const [internalComment, setInternalComment] = useState(note);

  //Logic
  const {
    canRead, canWrite,
    getMissionWorkflow
  } = useMissionWorkflowLogic({ idMission: missionId, missionCanceled: missionCanceled });
  

  //useEffect
  useEffect(() => {
    // reload variables if id set
    if (missionId > 0) {
      //Get Workflow configuration
      getMissionWorkflow();
    }
  }, [missionId]);
  
  useEffect(() => {
    // reload variables if id set
    setInternalComment(note);
  }, [note, missionId]);
  

  const handleChangeInternalComment = (event) => setInternalComment(event.target.value);

  const handleClose = () => {
    setInternalComment(note);
    setOpen(false);
  };

  const updateRow = () => {
    //rows
    setRows(rows.map((item) => {
      if (item.id == missionId) {
        item.internal_note = internalComment;
      }
      return item;
    }));
    //totalRows
    setTotalRows(totalRows.map((item) => {
      if (item.id == missionId) {
        item.internal_note = internalComment;
      }
      return item;
    }));
  }

  const handleClickSave = async (e) => {
    try {
      if (canWrite("internal_note")) {
        setLoadingSave(true);
        var data = new FormData();
        data.append('id_mission', Number(missionId));
        data.append('internal_note', internalComment);

        //API DATA SENT
        var jsonRequestData = JSON.stringify(Object.fromEntries(data));
        var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');    //PATCH ERREUR NULL

        var config = {
          url: MISSION_URL,
          method: 'put',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestDataFinal
        };
        await axios(config);

        updateRow();
        if (open) {
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingSave(false);
    }
  }


  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      className='modalPopin export'
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
    >
      <Box className='boxPopin' >
        <div className='headerPopin'>Internal note edition</div>
        <div className='contentPopin'>
          <FormControl className="textField-form-comment" sx={{ m: 1, width: '100%' }} size="small">
            <TextFieldCustom
              id="standard-multiline-internal"
              label="Internal note"
              multiline
              value={internalComment}
              onBlur={handleChangeInternalComment}
              variant="standard"
              fullWidth
              read={canRead("internal_note")}
              write={canWrite("internal_note")}
              controlFieldsStatus={true}
            />
          </FormControl>
        </div>
        <div className='footerPopin'>
          <Box textAlign="center" sx={{ '& > button': { m: 1 } }}>
            <LoadingButton
              onClick={handleClose}
              loadingIndicator="Cancel"
              variant="outlined"
              loading={loadingSave}
            >
              Cancel
            </LoadingButton>

            {Boolean(canWrite("internal_note")) && 
            <LoadingButton
              onClick={handleClickSave}
              loading={loadingSave}
              endIcon={<SaveRoundedIcon />}
              loadingPosition="end"
              variant="contained"
            >
              Save
            </LoadingButton>
            }
          </Box>
        </div>
      </Box>
    </Modal>
  );
})

export default InternalNotePopin